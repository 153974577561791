import packageInfo from '../../package.json';

export const environment = {
  version: `dev-${packageInfo.version}`,
  production: false,
  url_api: 'https://dev.dashboard.api.ultrapay.digital/api/v1',
  auth0: {
    domain: "development-ultrapay.eu.auth0.com",
    clientId: "kcrb6WCrMv6gFxOyNqhfyLZFGw7qxk6y",
    audience: "https://development-ultrapay.eu.auth0.com/api/v2/",
  }
};
